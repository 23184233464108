.home {
    display: flex;
    flex-direction: column;
    background-color: rgb(244, 244, 244);
    padding-top: 8rem;
}

.underlined {
    text-decoration: underline #52D766;
}

h1 {
    width: inherit;
    font-size: 80px;
    text-align: start;
    padding-left: 12%;
}

.home-text {
    margin-bottom: 15vh;
    text-align: center;
    place-self: center;
    font-size: 80px;
    width: 80%;

    span {
        color: white;
        background-color: #52D766;
        padding: 0 10px;
        border-radius: 25px;
    }
}

// QUERY PARAMS

@media screen and (max-width: 800px) {
    .home-text {
        font-size: 3rem;
    }

    h1 {
        width: inherit;
        font-size: 50px;
        text-align: start;
        padding-left: 5%;
        text-wrap: balance;
        padding-top: 15%;
    }
}