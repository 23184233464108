.footer-container {
  background-color: black;
  padding: 5% 12%;
  color: white;
}

.footer-texts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: space-between;
  
    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  
    a {
      margin-right: 10px;
      color: rgb(228, 228, 228);
      text-decoration: none;
  
      &:hover {
        text-decoration: underline;
  
      }
    }
  }
}

// QUERY PARAMS

@media screen and (max-width: 800px) {
  .footer-container {
    .footer-texts {
      flex-direction: column;
      align-items: flex-start;

      div {
        margin: 10px
      }
      margin-bottom: 20px;
    }
  }
}
