.navbar {
  background: 0 0;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 999;
  width: calc(100% - 100px);
  margin: 0 50px;
}

.logo-section {
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.title-logo {
  display: block;
  height: 50px;
  width: 180px;
}

.title-logo-small {
  display: none;
  height: 50px;
  width: 56px;
}


html:not([data-scroll='0']) {
  .title-logo {
    display: none;
  }

  .title-logo-small {
    display: block;
  }
}


// menu section -----------------------------------------------

.menu-section {
  box-shadow: 0 20px 25px -3px rgb(0 0 0 / 0.15), 0 4px 6px -4px rgb(0 0 0 / 0.15);
  padding: 15px;
  border-radius: 25px;

  &:hover {
    cursor: pointer;
  }

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
    transition: transform .2s;
    padding: 0;
    list-style: none;
    width: 450px;

    li {
      cursor: pointer;
      margin: 0 14;

      &:hover {
        text-decoration: underline #52D766;
        text-decoration-thickness: 2.5px;
      }
    }
  }


  a {
    font-size: 0.9rem;
    font-weight: bold;
    text-decoration: none;
    color: black;
  }
}

.AiOutlineMenu {
  display: block;
}

// QUERY PARAM -----------------------------------------------

@media screen and (max-width: 800px) {
  .menu-section ul {
    flex-direction: column;
    width: inherit;

    li {
      margin-bottom: 20px;
      a {
        font-size: 30px;
      }
    }
  }



}