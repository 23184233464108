.text-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 100px;
  margin-bottom: 10%;
  max-lines: 2;

  @media (max-width: 1068px) {
    margin: 0 5%;
  }

  @media (max-width: 480px) {
    margin: 0 1%;
  }
}

.text-item {
  flex-grow: 1;
  flex-basis: 30%;
  max-width: 50%;
  margin: 1%;
  cursor: pointer;
  transition: transform 0.2s;

  @media (max-width: 1068px) {
    flex: 0 1 calc(50% - 16px);
    max-width: 100%;
    height: 300px;
  }
  
  @media (max-width: 480px) {
    flex: 0 1 calc(100% - 16px);
    max-width: 100%;
    height: 300px;
  }

}

.text-item:hover {
  transform: scale(1.04);
}