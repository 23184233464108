.services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15vh;
  
  .top {
    margin: 0 12%;
    display: flex;
    flex-direction: row;
    text-align: right;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7vh;
    
    h2 {
      font-size: 80px;
      width: 30%;
      text-align: start;
    }

    p {
      width: 60%;
      text-align: justify;
      font-size: 25px;
    }
  
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: transparent;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;

      // height: 45vh;
      padding: 2%;
      text-align: center;

      // border: #52D766 2px solid;
      box-shadow: 0 20px 25px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
      border-radius: 15px;
      flex: 0 0 auto;
      width: 20vw;
      background: white;
      margin: 2%;

      h3 {
        // color: rgb(82, 215, 102, 0.1);
        margin-bottom: 10%;
      }

      p {
        text-align: justify;
        margin-top: 5%;
      }

      div {
        font-size: 80px;
      }
    }
  }

}

// QUERY PARAMS

@media screen and (max-width: 800px) {
  .services {
    margin: 0 5%;
    margin-bottom: 15vh;

    .top {
      flex-direction: column;

      h2 {
        font-size: 50px;
        width: 100%;
        text-align: center;
        margin-bottom: 7vh;
      }

      p {
        width: 100%;
      }
    }

    .cards {
      flex-direction: column;

      .card {
        width: 80vw;
        padding: 10%;
        // height: 3vh;
      }
    }
  }
}