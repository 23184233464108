.projectsResponsive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15vh;
  
  .top {
    margin: 0 12%;
    display: flex;
    flex-direction: row;
    text-align: right;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7vh;

    h2 {
      font-size: 80px;
      width: 60%;
      text-align: start;
    }

    div {
      display: flex;
      justify-content: end;
      width: 30%;

      a {
        display: block;
        box-shadow: 0 20px 25px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
        font-size: 20px;
        color: black;
        background: #52D766;
        padding: 15px;
        width: 250px;
        border-radius: 25px;
        border: none;
        font-weight: 550;
        text-align: center;
        text-decoration: none;
  
        &:hover {
          cursor: pointer;
        }
      }
    }

  }

  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    width: 100vw;

    
  }
}

// QUERY PARAMS

@media screen and (max-width: 800px) {
  .projectsResponsive {
    margin: 0 5%;
    margin-bottom: 15vh;

    .top {
      flex-direction: column;

      h2 {
        font-size: 50px;
        width: 100%;
        text-align: center;
        margin-bottom: 7vh;
      }

      div {
        width: 100%;
        justify-content: center;

        a {
          font-size: 30px;
        }
      }
    }
  }
}