// Settings
body {
    margin: 0;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "PT Sans", sans-serif;
}

ul {
    list-style: none;
}

.section-title {
    margin-top: 100px;
    font-size: 60px;
}

// Components
@import './components/allies';
@import './components/cards';
@import './components/footer';
@import './components/title';
@import './components/navbar';
@import './components/team';
@import './components/colombianMap';
@import './components/modal';
@import './components/carouselProjects';
@import './components/carousel';
@import './components/contact';
@import './components/services';
@import './components/projects';
@import './components/projectsResponsive';
@import './components/aboutUs';
@import './components/videoSection';
@import './components/formSection';
@import './components/input';

// Pages
@import './pages/home';
@import './pages/agro';
@import './pages/tech';
@import './pages/loading';
@import './pages/contactpage';