.title {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  font-size: 80px;
  
  .titleText {
    padding-left: 12%;
    font-weight: bold;
  }
  
  .animated-text {
    padding-left: 12%;
    margin: 2% 0;
    display: block;
    background-color: #52D766;
    width: inherit;
    color: #fff;
  }
}

// QUERY PARAMS

@media screen and (max-width: 800px) {
  .title {

    .titleText{
      font-size: 50px;
      padding-left: 5%;
    }
    
    .animated-text {
      font-size: 50px;
      padding-left: 5%;
      align-content: center;
    }
  }
}
