.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7vh 12%;
  background-color: #52D766;
  width: 100%;

  p {
    font-size: 60px;
    width: 60%;
    padding: 0;
    margin: 0;
    color: white;
    font-weight: bold;
  }

  a {
    display: block;
    box-shadow: 0 20px 25px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    font-size: 20px;
    color: black;
    background: white;
    padding: 15px;
    width: 250px;
    border-radius: 25px;
    border: none;
    font-weight: 550;
    text-align: center;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }
}

// QUERY PARAMS

@media screen and (max-width: 800px) {
  .contact {
    padding: 7vh 5%;
    flex-direction: column;

    p {
      text-align: center;
      font-size: 40px;
      width: 100%;
      padding-bottom: 7vh;
    }

    a {
      font-size: 30px;
    }
  }
}