.aboutUs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 12%;
  margin-bottom: 15vh;

  .top {
    display: flex;
    flex-direction: row;
    text-align: right;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7vh;

    h2 {
      font-size: 80px;
      width: 30%;
      text-align: start;
    }

    p {
      width: 60%;
      text-align: justify;
      font-size: 25px;
    }

  }

  button {
    box-shadow: 0 20px 25px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    font-size: 20px;
    color: black;
    background: #52D766;
    padding: 15px;
    // width: 60%;
    width: 250px;
    border-radius: 25px;
    border: none;
    font-weight: 550;

    &:hover {
      cursor: pointer;
    }
  }
}


// QUERY PARAMS

@media screen and (max-width: 800px) {
  .aboutUs {
    margin: 0 5%;
    margin-bottom: 15vh;

    .top {
      flex-direction: column;

      h2 {
        font-size: 50px;
        width: 100%;
        text-align: center;
        margin-bottom: 7vh;
      }

      p {
        width: 80%;
      }

    }
    button {
      font-size: 30px;
    }
  }
}