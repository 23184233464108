.Agro {
    margin-top: 80px
}

.Map-table-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 80vh;
    width: 100%;
}
  
.ColombianMapSvg {
    height: 80vh;
    width: auto;
}
 